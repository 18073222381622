import React from 'react';
import {useTranslation} from 'react-i18next';

import {CoiOverviewStore} from '../../../coi.list';
import {Box, IconButton, Paper, styled, Typography} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {CoiIncidentListItemCard} from './coiIncidentListCard';
import {useOnDistrictsOrStationsChange} from 'src/hooks/useDistrictChange';

export const CoiIncidentList: React.FC<
  CoiOverviewStore & {handleSelectIncident: (id: string) => void; selectedIncident: string | null, isFollowing: boolean}
> = ({handleSelectIncident, selectedIncident, isFollowing, ...store}) => {
  const ListContainer = styled('div')(({theme}) => ({
    padding: '20px 0',
    backgroundColor: '#EFF4F9',
    maxHeight: 'calc(100vh - 64px)',
    width: '380px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(1600)]: {
      width: '330px',
    },
  }));

  return (
    <ListContainer
      sx={{
        padding: '20px 0',
        backgroundColor: '#EFF4F9',
        maxHeight: 'calc(100vh - 64px)',
        width: '380px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DayStepper {...store} handleSelectIncident={handleSelectIncident} isDisabled={isFollowing} />
      {store.incidents && (
        <Box
          sx={{
            display: 'flex',
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            flexDirection: 'column',
            scrollbarWidth: '10px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {store.incidents.items?.map(item => (
            <CoiIncidentListItemCard
              key={item.id}
              incident={item}
              onSelectIncident={() => item.id && handleSelectIncident(item.id)}
              isSelected={item.id === selectedIncident}
            />
          ))}
        </Box>
      )}
    </ListContainer>
  );
};

const DayStepper: React.FC<CoiOverviewStore & {handleSelectIncident: (id: string) => void, isDisabled: boolean}> = ({
  handleSelectIncident,
  isDisabled,
  ...store
}) => {
  const {t} = useTranslation();
  const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';

  const handleClick = (dir: 'backwards' | 'forwards') => {
    handleSelectIncident('');
    const newDate = store.incidentListController.changeDate(dir);
    store.incidentListController.refetchData(newDate, district, null);
  };

  return (
    <Box
      sx={{
        minHeight: '64px',
        marginBottom: '24px',
        padding: '0 15px',
        width: '-webkit-fill-available',
      }}
    >
      <Paper
        elevation={1}
        sx={{
          minHeight: '64px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 8px',
        }}
      >
        <IconButton disabled={isDisabled} onClick={() => handleClick('backwards')}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography sx={{fontWeight: 'bold', color: isDisabled ? 'grey' : 'black'}}>
          {store.incidentListController.isToday() ? t('coi.incident-today') : moment(store.date).format('DD/MM')}
        </Typography>
        <IconButton disabled={store.incidentListController.isToday() || isDisabled} onClick={() => handleClick('forwards')}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

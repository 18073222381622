import {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Box, Select, MenuItem, TextField, Typography, InputLabel, FormControl} from '@mui/material';
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker';

import {CacheContext} from 'src/context/cache/cache.store';
import {EvacuationDto} from 'src/services/models/evacuation-dto';
import {EvacuationVehicleTypeDto} from 'src/services/models/evacuation-vehicle-type-dto';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

interface CoiEvacuationFormFootProps {
  values: EvacuationDto;
  handleChangeInput: (name: string, value: string | number) => void;
  defaultValues?: EvacuationDto;
  errors: string[];
  setErrors: (errors: string[]) => void;
}

export const CoiEvacuationFormFoot: FC<CoiEvacuationFormFootProps> = ({
  values,
  handleChangeInput,
  defaultValues,
  errors,
  setErrors,
}) => {
  const {t, i18n} = useTranslation();
  const {stations} = useContext(CacheContext);

  const renderStationOptions = () =>
    stations?.map(
      station =>
        station.id && (
          <MenuItem key={station.id} value={station.id}>
            {handleTranslationObject(i18n, station.name)}
          </MenuItem>
        ),
    );

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <FormControl fullWidth>
        <InputLabel id="vehicle-type-label">{t('coi.incident-emergency-vehicle')}</InputLabel>
        <Select
          disabled
          labelId="vehicle-type-label"
          sx={{marginBottom: '10px'}}
          value={values.type}
          defaultValue={defaultValues?.type}
          name="type"
          id="vehicle-type"
          label={t('coi.incident-emergency-vehicle')}
          onChange={e => handleChangeInput(e.target.name, e.target.value)}
        >
          {Object.values(EvacuationVehicleTypeDto).map(type => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="station-label">{t('report-grobo.station-grobo')}</InputLabel>
        <Select
          labelId="station-label"
          sx={{marginBottom: '10px'}}
          value={values.type}
          defaultValue={defaultValues?.type}
          name="station"
          id="station"
          label={t('report-grobo.station-grobo')}
          onChange={e => handleChangeInput(e.target.name, e.target.value)}
        >
          {renderStationOptions()}
        </Select>
      </FormControl>
      <Typography sx={{fontWeight: 'bold'}}>{t('coi.arrival')}</Typography>
      <DesktopTimePicker
        renderInput={props => (
          <TextField
            helperText={errors?.includes('arrivalTime') ? t('coi.evacuation-method-errors.arrivalTime') : ''}
            {...props}
          />
        )}
        value={values.arrivalTime || defaultValues?.arrivalTime}
        label={t('coi.arrival-time')}
        maxTime={moment(values?.departureTime || defaultValues?.departureTime)}
        onError={newError => {
          if (newError) {
            setErrors([...errors, 'arrivalTime']);
          } else {
            setErrors(errors.filter(error => error !== 'arrivalTime'));
          }
        }}
        onChange={e => {
          if (e) {
            handleChangeInput('arrivalTime', e.toISOString());
          }
        }}
      />
      <Typography sx={{fontWeight: 'bold'}}>{t('coi.departure')}</Typography>
      <DesktopTimePicker
        renderInput={props => (
          <TextField
            helperText={errors?.includes('departureTime') ? t('coi.evacuation-method-errors.departureTime') : ''}
            {...props}
          />
        )}
        value={values.departureTime || defaultValues?.departureTime}
        label={t('coi.departure-time')}
        minTime={moment(values?.arrivalTime || defaultValues?.arrivalTime)}
        onChange={e => {
          if (e) {
            handleChangeInput('departureTime', e.toISOString());
          }
        }}
        onError={newError => {
          if (newError) {
            setErrors([...errors, 'departureTime']);
          } else {
            setErrors(errors.filter(error => error !== 'departureTime'));
          }
        }}
      />
      <TextField
        sx={{marginTop: '10px'}}
        id="numberOfPassengers"
        type="number"
        name="numberOfPassengers"
        defaultValue={defaultValues?.numberOfPassengers}
        value={values.numberOfPassengers}
        label={t('coi.number-of-passengers')}
        onChange={e => handleChangeInput(e.target.name, parseInt(e.target.value))}
      />
    </Box>
  );
};

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import i18next from 'i18next';
import CheckInOutIcon from 'src/components/icons/CheckinOut';
import DashboardIcon from 'src/components/icons/Dashboard';
import FolderIcon from 'src/components/icons/Folder';
import InventoryIcon from 'src/components/icons/Inventory';

import {useTranslation} from 'react-i18next';
import ListMenuItem from '../MenuItem/ListMenuItem';
import {MenuList, SelectMenu, SelectMenuItem} from './Menu.styles';
import {AuthContext, AuthPermissions} from 'src/context/authentication/store.auth';
import {SettingsContext} from 'src/context/settings/settings.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {SelectChangeEvent} from '@mui/material';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';

export default function Menu() {
  const {filteredMissions} = React.useContext(MissionContext);
  //@ts-ignore
  const notAssignList = filteredMissions.filter(el => el.owner === null || el.owner === undefined);

  //@ts-ignore
  const assignList = filteredMissions.filter(el => el.owner !== null || el.owner !== undefined);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [collapseOverview, setCollapseOverview] = React.useState(true);
  const [collapseCheckIn, setCollapseCheckIn] = React.useState(false);
  const [language, setLanguage] = React.useState(i18next.language);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {logOut, permissions} = React.useContext(AuthContext);
  const {EXTERNAL_LINK_SUPPORT_SERVICE_NOW, setPrevRoute} = React.useContext(SettingsContext);
  const {variables} = React.useContext(EnvironmentContext);
  const isAbleToPrint = permissions?.includes(AuthPermissions.PrintStickeringMissions);

  React.useEffect(() => {
    navigate(`/mission-overview`);
    setSelectedIndex(1);
  }, [history]);

  React.useEffect(() => {
    localStorage.setItem('language', i18next.language);
  }, [i18next.language]);

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
  };
  const handleCollapseOverview = () => {
    setCollapseOverview(!collapseOverview);
  };

  const handleCollapseCheckIn = () => {
    setCollapseCheckIn(!collapseCheckIn);
  };

  const getLangLabel = () => {
    let lang = t('choose-language.language-en');
    switch (language) {
      case 'en':
        lang = t('choose-language.language-en');

        break;
      case 'nl':
        lang = t('choose-language.language-nl');

        break;
      case 'fr':
        lang = t('choose-language.language-fr');

        break;

      default:
        break;
    }
    return lang;
  };

  return (
    <div style={{flexDirection: 'column', justifyContent: 'space-between', display: 'flex', height: '100%'}}>
      <MenuList>
        <ListMenuItem
          handleOnClick={() => {
            handleCollapseOverview();
            setSelectedIndex(0);
          }}
          index={0}
          selectedIndex={selectedIndex}
          text={t('launchpad.menu.mission-overview')}
          icon={<DashboardIcon />}
        />
        <Collapse in={collapseOverview} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListMenuItem
              handleOnClick={() => {
                setSelectedIndex(1);
                setPrevRoute('/mission-overview');
                navigate(`/mission-overview`);
              }}
              index={1}
              selectedIndex={selectedIndex}
              text={t('launchpad.menu.assigned')}
              isCollapsed
              badgeNumber={notAssignList.length}
            />
            <ListMenuItem
              handleOnClick={() => {
                setSelectedIndex(2);
                setPrevRoute('/mission-assign');
                navigate(`/mission-assign`);
              }}
              index={2}
              selectedIndex={selectedIndex}
              text={t('launchpad.menu.follow-up')}
              isCollapsed
              badgeNumber={assignList.length}
            />
          </List>
        </Collapse>
        <ListMenuItem
          handleOnClick={() => {
            setSelectedIndex(3);
            navigate(`/reservation`);
          }}
          index={3}
          selectedIndex={selectedIndex}
          text={t('launchpad.menu.list')}
          icon={<FolderIcon />}
        />
        {isAbleToPrint ? (
          <ListMenuItem
            handleOnClick={() => {
              setSelectedIndex(4);
              navigate(`/print-stickers`);
            }}
            index={4}
            selectedIndex={selectedIndex}
            text={t('launchpad.card-print-sticker.title')}
            icon={<InventoryIcon />}
          />
        ) : null}
        <ListMenuItem
          handleOnClick={() => {
            handleCollapseCheckIn();
            setSelectedIndex(6);
          }}
          index={5}
          selectedIndex={selectedIndex}
          text={t('launchpad.card-checkin-checkout.title')}
          icon={<CheckInOutIcon />}
        />
        <Collapse in={collapseCheckIn} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListMenuItem
              handleOnClick={() => {
                setSelectedIndex(6);
                navigate(`/checkin`);
              }}
              index={6}
              selectedIndex={selectedIndex}
              text={t('launchpad.menu.my-checkin')}
              isCollapsed
            />
            <ListMenuItem
              handleOnClick={() => {
                setSelectedIndex(7);
                navigate(`/checkin-overview`);
              }}
              index={7}
              selectedIndex={selectedIndex}
              text={t('launchpad.menu.overview')}
              isCollapsed
            />
          </List>
        </Collapse>
        <Divider variant="middle" sx={{color: '#707070', mt: 1}} />
        <Divider variant="middle" sx={{color: '#707070'}} />
      </MenuList>
      <MenuList sx={{width: '100%'}}>
        <ListMenuItem
          handleOnClick={() => {
            setSelectedIndex(11);
            window.open(EXTERNAL_LINK_SUPPORT_SERVICE_NOW, '_blank');
          }}
          index={11}
          selectedIndex={selectedIndex}
          text={t('launchpad.menu.support')}
        />
        <ListItem selected={selectedIndex === 12} onClick={() => setSelectedIndex(12)}>
          <SelectMenu
            disableUnderline
            variant="standard"
            value={language}
            label={getLangLabel()}
            onChange={e => handleChange(e as SelectChangeEvent)}
            selectedIndex={selectedIndex}
            defaultValue={language}
          >
            <SelectMenuItem value="En"> {t('choose-language.language-en')} </SelectMenuItem>
            <SelectMenuItem value="Nl"> {t('choose-language.language-nl')} </SelectMenuItem>
            <SelectMenuItem value="Fr"> {t('choose-language.language-fr')} </SelectMenuItem>
          </SelectMenu>
        </ListItem>
        <ListMenuItem
          index={8}
          handleOnClick={() => {
            setSelectedIndex(8);
            navigate('/legend');
          }}
          selectedIndex={selectedIndex}
          text={t('launchpad.menu.legend')}
        />
        <Divider variant="middle" sx={{color: '#707070'}} />
        <ListMenuItem
          handleOnClick={() => logOut()}
          index={13}
          selectedIndex={selectedIndex}
          text={t('settings.logout')}
          icon={<LogoutIcon />}
        />
        <ListItem>
          <ListItemText
            primary={`DiCOS v${variables.APP_VERSION}`}
            sx={{fontSize: '0.8rem', color: '#777777'}}
            disableTypography
          />
        </ListItem>
      </MenuList>
    </div>
  );
}

import React, {useContext, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from './coiCreateIncidentOverview';
import {CacheContext} from 'src/context/cache/cache.store';
import {DistrictDto, LanguageDto, LocalizedTextDto, ModelError, StationDto} from 'src/services/models';
import {
  AddressWithMainLocationDto,
  CoiCreateIncidentLocationActionTypes,
  StationWithMainLocationDto,
  TrainWithMainLocationDto,
} from './reducer';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {getStationPresences} from '../../coiUtils/coiGetPresences';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {AxiosError} from 'axios';
import {CoiAssignAssistButon, CoiAssignCoiButton, IOSSwitch} from '../common';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {handleFilterStations} from 'src/scenes/change-route/NewRoutePlanner/StationAutoComplete';

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import {t} from 'i18next';
import {localStorageKeys} from 'src/utils/localStorageKeys';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export enum TargetType {
  PT_CAR = 'PT_CAR',
  TRAIN = 'TRAIN',
  ADDRESS = 'ADDRESS',
}

export type CoiTargetType =
  | {type: TargetType.PT_CAR; payload: StationWithMainLocationDto}
  | {
      type: TargetType.TRAIN;
      payload: {indOfTrain: number; train: TrainWithMainLocationDto};
    }
  | {type: TargetType.ADDRESS};

export interface CoiModalPropsTrain {}

export interface CoiModalPropsPtCar {}

export interface CoiModalPropsAddress {}

// Train -> 565
//if you select a train -> you can select a ptcar aswell
//Select address -> only address

// COI -> Responsible -> 1 COI per lcoation
// Assistansts -> help -> multiple assistants

//COI for a train and/or ptcar

//COi -> can not be an assistant
// Assistant can not be a coi

export interface CoiAssignCoiModalProps {
  open: boolean;
  onClose: () => void;
  target: CoiTargetType;
  assignIndex: number | undefined; //Only needed on the train COI/assitants
  assignCoi: (employee: StationPresenceDto, index?: number) => void;
  unAssignCoi: (index?: number) => void;
  assignAssist: (assist: StationPresenceDto, index?: number) => void;
  unAssignAssist: (id: string, index?: number) => void;
  address?: AddressWithMainLocationDto;
  trains?: TrainWithMainLocationDto[];
  ptcar?: StationWithMainLocationDto;
  isHandlingPutCall?: boolean;
  reAssign?:
    | undefined
    | {
        handleCancel: () => void;
        handleSave: (assignIndex: number | undefined) => void;
      };
}

export const CoiAssignCoiModal = ({
  open,
  onClose,
  target,
  assignIndex,
  address,
  trains,
  ptcar,
  assignCoi,
  assignAssist,
  unAssignAssist,
  unAssignCoi,
  reAssign,
  isHandlingPutCall = false,
}: CoiAssignCoiModalProps) => {
  const {i18n, t} = useTranslation();
  const {districts, stations} = useContext(CacheContext);

  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const selectedDistrictId = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT);

  const [selectedDistrict, setSelectedDistrict] = useState<string>(selectedDistrictId || '');
  const [selectedStation, setSelectedStation] = useState<string>('ALL');

  const [COIS, setCOIS] = React.useState<StationPresenceDto[] | undefined>(undefined);

  const [isSearchingCois, setIsSearchingCois] = React.useState(false);

  const [coiOnly, setCoiOnly] = React.useState(true);

  const prependAllOption = (stations: StationDto[]) => {
    const newArray = stations.slice();
    newArray.unshift({
      id: 'ALL',
      districtId: selectedDistrict,
      name: [
        {
          language: LanguageDto.Dutch,
          text: 'Volledig District',
        },
        {
          language: LanguageDto.English,
          text: 'Complete District',
        },
        {
          language: LanguageDto.French,
          text: 'District Complète',
        },
      ],
    } as StationDto);

    return newArray;
  };

  const handleDistrictChange = (val: string) => {
    const districtId = val;
    setSelectedDistrict(districtId || '');
    setSelectedStation('ALL');
  };

  const handleStationChange = (val: string) => {
    setSelectedStation((val as string) || '');
  };

  const handleSearch = async () => {
    setIsSearchingCois(true);
    if (!selectedStation) {
      return setIsSearchingCois(false);
    }

    const stationIds =
      selectedStation === 'ALL'
        ? (stations as StationDto[]).filter(el => el.districtId === selectedDistrict).map(el => el.id as string)
        : [selectedStation];

    await getStationPresences(variables.BASE_ENDPOINT, {stationIds: stationIds})
      .then(res => {
        if (res) {
          setCOIS(res);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          validateNetworkCall(error.response.status, error.response.data as ModelError);
        }
        return undefined;
      })
      .finally(() => {
        setIsSearchingCois(false);
      });
  };

  const handleCoiOnly = () => {
    setCoiOnly(prev => !prev);
  };

  const coiText = {
    assignIncident: t('coi.assign-incident'),
    sations: t('pmr.search.stations'),
    search: t('pmr.search.search'),
    availaible: t('coi.select-available'),
    coiOnlt: t('coi.coi-only'),
  };

  return (
    <Dialog open={open || isHandlingPutCall} onClose={onClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
      <DialogTitle
        sx={{
          borderBottom: 'solid 1px grey',
          paddingTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{coiText.assignIncident}</h3>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={isHandlingPutCall}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isHandlingPutCall && (
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isHandlingPutCall && (
          <>
            {' '}
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: 'auto auto',
                gap: '25px',
                padding: '25px 0px',
              }}
            >
              <h4>{coiText.sations}</h4>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '20% 1fr 20%',
                  gap: '15px',
                }}
              >
                <Select value={selectedDistrict} onChange={e => handleDistrictChange(e.target.value)}>
                  {(districts as DistrictDto[]).map(el => (
                    <MenuItem key={el.id} value={el.id || ''}>
                      {handleTranslationObject(i18n, el.name as LocalizedTextDto[])}
                    </MenuItem>
                  ))}
                </Select>

                <Autocomplete
                  value={prependAllOption(stations as StationDto[]).find(el => el.id === selectedStation) || null}
                  onChange={(_e, newValue) => handleStationChange(newValue?.id || 'ALL')}
                  options={
                    selectedDistrict
                      ? prependAllOption(
                          (stations as StationDto[])
                            .filter(el => el.districtId === selectedDistrict)
                            .sort((a, b) =>
                              handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
                                handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
                              ),
                            ),
                        )
                      : []
                  }
                  getOptionLabel={option => handleTranslationObject(i18n, option.name as LocalizedTextDto[])}
                  renderInput={params => <TextField {...params} label={t('select.station')} />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterOptions={(el, state) => {
                    return handleFilterStations(el, state.inputValue);
                  }}
                />

                <Button
                  variant="outlined"
                  disabled={selectedDistrict.length === 0 || selectedStation.length === 0}
                  onClick={handleSearch}
                >
                  {coiText.search}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: 'auto auto',
                gap: '25px',
                padding: '25px 0px',
              }}
            >
              <Box sx={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
                <h4>{coiText.availaible}</h4>
                <Box>
                  <span style={{fontWeight: 'bold'}}>{t('common.coi')} </span>{' '}
                  <IOSSwitch
                    checked={coiOnly}
                    onChange={handleCoiOnly}
                    disabled={COIS?.length === 0 || COIS === undefined}
                  />
                </Box>
              </Box>
              <Box sx={{minHeight: '400px'}}>
                {isSearchingCois && <SearchingForCois />}
                {!isSearchingCois && COIS === undefined && <SearchForCois />}
                {!isSearchingCois && COIS !== undefined && COIS.length === 0 && <NoCoisFound />}
                {!isSearchingCois && COIS !== undefined && COIS.length > 0 && (
                  <CoiList
                    cois={coiOnly ? COIS.filter(c => c.isCoi) : COIS}
                    assignCoi={assignCoi}
                    assignAssist={assignAssist}
                    assignIndex={assignIndex}
                    target={target}
                    unAssignAssist={unAssignAssist}
                    unAssignCoi={unAssignCoi}
                    address={address}
                    ptcar={ptcar}
                    trains={trains}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      {reAssign !== undefined && (
        <DialogActions>
          <Button onClick={() => reAssign.handleCancel()}>{t('settings.changelanguage.cancel')}</Button>
          <Button onClick={() => reAssign.handleSave(assignIndex)} variant="contained">
            {t('settings.changelanguage.save')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const SearchingForCois = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'grid',
        placeItems: 'center',
        backgroundColor: '#EFF4F9',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const SearchForCois = () => {
  const {t} = useTranslation();
  return (
    <Box sx={{width: '100%', height: '100%', display: 'grid', placeItems: 'center', backgroundColor: '#EFF4F9'}}>
      <Box sx={{fontWeight: 'bold'}}>{t('search-to-find-coi')}</Box>
    </Box>
  );
};

const NoCoisFound = () => {
  const {t} = useTranslation();
  return (
    <Box sx={{width: '100%', height: '100%', display: 'grid', placeItems: 'center', backgroundColor: '#EFF4F9'}}>
      <Box sx={{fontWeight: 'bold'}}>{t('no-cois-station')}</Box>
    </Box>
  );
};

interface CoiListProps {
  cois: StationPresenceDto[];
  assignCoi: (employee: StationPresenceDto, index?: number) => void;
  unAssignCoi: (index?: number) => void;
  assignAssist: (assist: StationPresenceDto, index?: number) => void;
  unAssignAssist: (id: string, index?: number) => void;
  assignIndex: number | undefined;
  target: CoiTargetType;
  address?: AddressWithMainLocationDto;
  ptcar?: StationWithMainLocationDto;
  trains?: TrainWithMainLocationDto[];
}

const CoiList = ({
  cois,
  assignAssist,
  assignCoi,
  unAssignCoi,
  unAssignAssist,
  assignIndex,
  target,
  address,
  ptcar,
  trains,
}: CoiListProps) => {
  const seperateCoisBasedOnDistrictId = () => {
    const result: Array<{stationId: string; cois: StationPresenceDto[]}> = [];
    cois.forEach((coi, ind) => {
      if (ind === 0) {
        return result.push({stationId: coi.stationId || '', cois: [coi]});
      }

      let entryId = '';
      let entryInd = 0;

      const doesEntryExist = result.find((el, ind) => {
        entryInd = ind;
        entryId = coi.stationId || '';
        return el.stationId === coi.stationId;
      });

      if (doesEntryExist) {
        result[entryInd].cois.push(coi);
      } else {
        result.push({stationId: entryId, cois: [coi]});
      }
    });
    return result;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: '600px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gap: '8px',
        gridTemplateColumns: '1fr',
      }}
    >
      {seperateCoisBasedOnDistrictId().length === 0 ? (
        <NoCoisFound />
      ) : (
        seperateCoisBasedOnDistrictId().map(coi => (
          <CoiAccordeon
            key={coi.stationId}
            cois={coi.cois}
            assignCoi={assignCoi}
            assignAssist={assignAssist}
            assignIndex={assignIndex}
            target={target}
            unAssignAssist={unAssignAssist}
            unAssignCoi={unAssignCoi}
            address={address}
            ptcar={ptcar}
            trains={trains}
          />
        ))
      )}
    </Box>
  );
};

const CoiAccordeon = ({
  cois,
  assignAssist,
  assignCoi,
  unAssignCoi,
  unAssignAssist,
  assignIndex,
  target,
  address,
  ptcar,
  trains,
}: CoiListProps) => {
  const {i18n} = useTranslation();
  return (
    <Accordion
      elevation={0}
      disableGutters={true}
      sx={{
        '&.MuiAccordion-root': {
          '::before': {
            height: 0,
            border: 'none',
          },
        },
      }}
    >
      <AccordionSummary
        sx={{backgroundColor: '#eff4f9', borderRadius: '8px 8px 0px 0px', border: 'solid 1px #d7d7d7', color: 'black'}}
        expandIcon={<KeyboardArrowDownIcon sx={{color: '#0069b4'}} />}
      >
        <span style={{fontWeight: 'bold'}}>{cois[0].stationName}</span>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderLeft: 'solid 1px #d7d7d7',
          borderBottom: 'solid 1px #d7d7d7',
          borderRight: 'solid 1px #d7d7d7',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateRows: `repeat(${cois.length}, 60px)`,
            gap: '15px',
          }}
        >
          {cois.map(coi => (
            <CoiCOISItem
              key={coi.employeeId}
              coi={coi}
              assignCoi={assignCoi}
              assignAssist={assignAssist}
              assignIndex={assignIndex}
              target={target}
              unAssignAssist={unAssignAssist}
              unAssignCoi={unAssignCoi}
              address={address}
              ptcar={ptcar}
              trains={trains}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

interface CoiCOISItemProps {
  coi: StationPresenceDto;
  assignCoi: (employee: StationPresenceDto, index?: number) => void;
  unAssignCoi: (index?: number) => void;
  assignAssist: (assist: StationPresenceDto, index?: number) => void;
  unAssignAssist: (id: string, index?: number) => void;
  assignIndex: number | undefined;
  trains?: TrainWithMainLocationDto[];
  ptcar?: StationWithMainLocationDto;
  address?: AddressWithMainLocationDto;
  target: CoiTargetType;
}

type CurrentCoiAssignProps =
  | {type: TargetType.TRAIN; payload: {index: number; trains: TrainWithMainLocationDto[]; coi: StationPresenceDto}}
  | {type: TargetType.PT_CAR; payload: {ptcar: StationWithMainLocationDto; coi: StationPresenceDto}}
  | {type: TargetType.ADDRESS; payload: {address: AddressWithMainLocationDto; coi: StationPresenceDto}};

type CurrentCoiAssistantProps =
  | {type: TargetType.TRAIN; payload: {coi: StationPresenceDto; index: number; trains: TrainWithMainLocationDto[]}}
  | {type: TargetType.PT_CAR; payload: {coi: StationPresenceDto; ptcar: StationWithMainLocationDto}}
  | {type: TargetType.ADDRESS; payload: {coi: StationPresenceDto; address: AddressWithMainLocationDto}};

// Helper function to check if the COI is already assigned
const hasCurrentCoiBeenAssigned = (props: CurrentCoiAssignProps): boolean => {
  const {coi} = props.payload;

  switch (props.type) {
    case TargetType.TRAIN:
      const existingCoi = props.payload.trains[props.payload.index]?.assignedCoi;
      return existingCoi?.employeeId === coi.employeeId;
    case TargetType.PT_CAR:
      return props.payload.ptcar?.assignedCoi?.employeeId === coi.employeeId;
    case TargetType.ADDRESS:
      return props.payload.address?.assignedCoi?.employeeId === coi.employeeId;
    default:
      return false;
  }
};

// Helper function to check if the COI is an assistant
const isCurrentCoiAssistant = (props: CurrentCoiAssistantProps): boolean => {
  const {coi} = props.payload;
  const getAssistantEmployeeIds = (assistants: StationPresenceDto[] | undefined) =>
    assistants?.map(assistant => assistant.employeeId);

  switch (props.type) {
    case TargetType.TRAIN:
      const trainAssistants = props.payload.trains[props.payload.index]?.assistants;
      return getAssistantEmployeeIds(trainAssistants)?.includes(coi.employeeId) || false;
    case TargetType.PT_CAR:
      return getAssistantEmployeeIds(props.payload.ptcar?.assistants)?.includes(coi.employeeId) || false;
    case TargetType.ADDRESS:
      return getAssistantEmployeeIds(props.payload.address?.assistants)?.includes(coi.employeeId) || false;
    default:
      return false;
  }
};

const CoiCOISItem = ({
  coi,
  assignCoi,
  assignAssist,
  unAssignAssist,
  unAssignCoi,
  assignIndex,
  target,
  address,
  ptcar,
  trains,
}: CoiCOISItemProps) => {
  const {i18n} = useTranslation();

  // Helper to verify if the COI is already an assistant
  const verifyCoiAssistant = (): boolean => {
    switch (target.type) {
      case TargetType.TRAIN:
        return assignIndex !== undefined && assignIndex !== null && trains
          ? isCurrentCoiAssistant({type: TargetType.TRAIN, payload: {coi, index: assignIndex, trains}})
          : false;
      case TargetType.PT_CAR:
        return ptcar ? isCurrentCoiAssistant({type: TargetType.PT_CAR, payload: {coi, ptcar}}) : false;
      case TargetType.ADDRESS:
        return address ? isCurrentCoiAssistant({type: TargetType.ADDRESS, payload: {coi, address}}) : false;
      default:
        return false;
    }
  };

  // Helper to verify if the COI is already assigned
  const verifyCoiAssignment = (): boolean => {
    switch (target.type) {
      case TargetType.TRAIN:
        return assignIndex !== undefined && assignIndex !== null && trains
          ? hasCurrentCoiBeenAssigned({type: TargetType.TRAIN, payload: {coi, index: assignIndex, trains}})
          : false;
      case TargetType.PT_CAR:
        return ptcar ? hasCurrentCoiBeenAssigned({type: TargetType.PT_CAR, payload: {coi, ptcar}}) : false;
      case TargetType.ADDRESS:
        return address ? hasCurrentCoiBeenAssigned({type: TargetType.ADDRESS, payload: {coi, address}}) : false;
      default:
        return false;
    }
  };

  const noCoiSelectec = (): boolean => {
    switch (target.type) {
      case TargetType.TRAIN:
        return assignIndex !== undefined && assignIndex !== null && trains
          ? trains[assignIndex].assignedCoi?.employeeId === undefined ||
              trains[assignIndex].assignedCoi?.employeeId === null
          : false;
      case TargetType.PT_CAR:
        return ptcar ? ptcar.assignedCoi?.employeeId === undefined || ptcar.assignedCoi?.employeeId === null : false;
      case TargetType.ADDRESS:
        return address
          ? address.assignedCoi?.employeeId === undefined || address.assignedCoi?.employeeId === null
          : false;
      default:
        return false;
    }
  };

  return (
    <Box
      sx={{
        border: 'solid 1px #d7d7d7',
        borderRadius: '5px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr) minmax(142px, auto) minmax(165px, auto)',
        gap: '20px',
        padding: '5px 12px',
        height: 'auto',
        alignItems: 'center',
        '@media (min-width: 1250px)': {
          gridTemplateColumns: 'repe',
        },
      }}
    >
      <Tooltip title={coi.name}>
        <Box
          sx={{
            justifySelf: 'start',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px',
            fontWeight: 'bold',
            '@media(max-width: 1250px)': {
              maxWidth: '100px',
            },
          }}
        >
          {coi.name}
        </Box>
      </Tooltip>
      <Tooltip title={handleTranslationObject(i18n, coi.role as LocalizedTextDto[])}>
        <Box
          sx={{
            justifySelf: 'start',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px',
            '@media(max-width: 1250px)': {
              maxWidth: '100px',
            },
          }}
        >
          {handleTranslationObject(i18n, coi.role as LocalizedTextDto[])}
        </Box>
      </Tooltip>
      <span style={{display: 'flex', gap: '5px', justifySelf: 'end'}}>
        <LocalPhoneOutlinedIcon color={'info'} />
        <a
          href={`dica:${coi.phoneNumber?.replace('+', '000')}`}
          style={{justifySelf: 'end', textDecoration: 'none', fontWeight: 'bold'}}
        >
          {coi.phoneNumber}
        </a>
      </span>
      <CoiAssignCoiButton
        disabled={verifyCoiAssistant()}
        style={{justifySelf: 'center'}}
        onClick={() => (verifyCoiAssignment() ? unAssignCoi(assignIndex) : assignCoi(coi, assignIndex))}
        isCoi={verifyCoiAssignment()}
      >
        {t('coi.assign-spi')}
      </CoiAssignCoiButton>
      <CoiAssignAssistButon
        disabled={verifyCoiAssignment() || noCoiSelectec()}
        style={{justifySelf: 'center'}}
        onClick={() =>
          verifyCoiAssistant() ? unAssignAssist(coi.employeeId || '', assignIndex) : assignAssist(coi, assignIndex)
        }
        isAssistant={verifyCoiAssistant()}
      >
        {t('coor-role.assignerButton')} {t('mission-details-v2.journey.assist')}
      </CoiAssignAssistButon>
    </Box>
  );
};
